.report-top {
  display: flex;
  padding: 25px;
  gap: 20px;
  flex-direction: column;
  width: 100%;
}

.report-opts-top {
  gap: 5vh;
  display: flex;
  flex-direction: column;
  color: white;

}

.report-opt-box-1,
.report-opt-box-2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  text-align: center;
  background: #2f2f2f;
  padding: 2vw 1.5vw;
  border-radius: 16px;
  
}

.report-opt-box-1 label,
.report-opt-box-2 label {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.report-opt-box-1 label div div:first-of-type {
  background-color: rgba(47, 47, 47, 1);
  box-shadow: 0px 7.2px 10.8px 5.4px rgba(0, 0, 0, 0.15), 0px 3.6px 3.6px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  border: none;
  color: white;
  border-radius: 10px;
  height: 50px;
  padding: 10px;
  width: 100%;
}

.report-opt-box-2 label div div:first-of-type,
.report-opt-box-2 input {
  border-color: var(--bg_light);
}

.report-opt-box-2 input {
  padding: 1.5vh 1vw;
  border: none;
  color: var(--form_gray);
}

.report-data-top {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  gap: 15px;
}

.report-data-opts-top {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.report-data-row,
.report-data-header {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-items: center;
}

.report-data-row {
  background: var(--bg_light);
  border-bottom: 1px solid #333333;
  transition: .5s;
}

.report-data-row:hover {
  background: var(--bg_light);
}

.report-data-header p {
  font-family: gilroy-bold, sans-serif;
  letter-spacing: 1px;
}

.report-data-box {
  background: var(--bg_light);
  border-radius: 16px;
  border: 1px solid #333333;
  border-radius: 16px;
}

.custom-dropdown {
  position: absolute;
  bottom: -80px;
  background: var(--bg_light);
  box-shadow: 0px 6px 10px 4px rgb(0 0 0 / 15%), 0px 2px 3px rgb(0 0 0 / 30%);
  border-radius: 16px;
  padding: 2vh 1.6vw;
  flex-direction: column;
  gap: 10px;
}

.report-dropdown p {
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.page-nav-box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 0 0 16px 16px;
}

.report-inps {
  text-align: left;
  display: grid;
  width: 100%;
  gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;
}

.report-dropdown {
  position: relative;
  cursor: pointer;
  display: inline-block;
  margin-top: 30px;
}

.report-dropdown-content {
  display: none;
  position: absolute;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  right: 0;
  background: rgb(219, 219, 219);
  bottom: -85px;
  border-radius: 10px;
}

.report-dropdown-content p {
  color: var(--bg_light) !important;
}

.report-dropdown:hover .report-dropdown-content {
  display: block;
}

.report-but-grp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

@media only screen and (max-width:767px) {
  .report-top {
    padding: 16px;
    padding-bottom: 24%;
  }

  .report-opts-top {
    width: 100%;
  }

  .report-opt-box-1,
  .report-opt-box-2 {
    background: var(--bg_light);
    padding: 5%;
  }

  .report-data-top {
    width: 100%;
  }

  .report-data,
  .report-data-header {
    overflow: auto;
  }

  .report-data-row,
  .report-data-header {
    grid-template-columns: repeat(5, auto);
    gap: 15px;
    border-bottom: 1px solid #ffffff26;
  }

  .report-data-box {
    background: #1E1E2D;
    padding: 10px;
  }

  .biz-opts-top {
    justify-content: center;
  }
  .report-inps{
    grid-template-columns: 1fr;
  }
  .reset-but{
    display: none;
  }
}


