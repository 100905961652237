.custom-swal-popup {
    background: rgba( 24, 22, 22, 0.9 );
    backdrop-filter: blur( 19px );
    -webkit-backdrop-filter: blur( 19px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
color: white;
}

div:where(.swal2-icon).swal2-warning {
    border-color: red;
    color: red;
}
div:where(.swal2-container) button:where(.swal2-styled):where(.swal2-confirm) {
/* background-color: rgb(98, 94, 94); */
background-color: white;
font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
font-weight: bold;
color: black;
  cursor: pointer;
  transition: .5s;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
    
}
