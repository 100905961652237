.warning-top {
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: white;
}

.warning-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 2% 3% 2% 3%;
  width: 100%;
}

.warning-cont {
  width: 100%;
  height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width:600px){
  .warning-cont{
    padding: 6%;
  }
  .warning-header{
    padding: 5%;
    justify-content: flex-end;
  }
  .warning-tag p{
    text-align: center;
  }
}