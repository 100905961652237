.device-top{
  padding: 2vw;
}
.proj-det{
  color: white;
  gap: 10px !important;
}
.proj-det h4{
  margin: 0;
}
.device-card-footer{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}
.device-card-but{
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 3px;
}
.device-biz-det,.device-proj-det{
  align-items: flex-start !important;
}
.green-text{
  color: rgb(0, 255, 0);
}
.red-text{
  color:#FF0000;
}
@media only screen and (max-width:767px){
  .device-proj-det{
    display: none;
  }
  .device-biz-det{
    align-items: center !important;
  }
  .device-top{
    padding: 16px;
  }
  .device-card-but{
    align-items: stretch;
  }
}