.device-table{
  width: 100%;
  margin: auto;
  border: 1px solid rgb(83, 83, 83);
  margin-bottom: 15px;
  border-radius: 10px;
}
.table-row{
  display: grid;
  grid-template-columns: .5fr 1fr 1fr 1fr;
  justify-items: stretch;
  border-bottom: 1px solid rgb(83, 83, 83);
  padding: 0 15px;
  gap: 10px;
  font-size: 13px;
  color: white;
  align-items: center;
}
.table-row-types{
  grid-template-columns: .5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
.gap-5{
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}
.table-row:last-of-type{
  border-bottom: none;
}
.table-row:first-of-type{
  font-weight: bold;
  background: var(--bg_light);
  border-radius: 10px;
  border-bottom: none;
}
.font-bold{
  font-weight: bold;
}
.table-row a{
  text-decoration: none;
  color: rgb(80, 80, 80);
  transition: 1s;
}
.table-row a:hover{
  color: rgb(13, 13, 13);
  transform: scale(1.1);
}