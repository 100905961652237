/* FlightMap.css */
.custom-blue-dot-icon {
  background: none;
}

.blue-dot {
  width: 10px;
  height: 10px;
  background-color: blue;
  border-radius: 50%;
  position: relative;
}
.custom-green-dot-icon {
  background: none;
}

.green-dot {
  width: 10px;
  height: 10px;
  background-color: green;
  border-radius: 50%;
  position: relative;
}

.secondmap{
  width: 90% !important;
  height: 85% !important;
  margin-left: 10px;
  margin-top: 10px;
}
.second{
  width: 100% !important;
  height: 100% !important;
  color: white !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.inp{
  font-family: sans-serif;
    font-weight: bold;
    margin-left: 100px;
}
