.biz-top{
  padding: 2vw;
  color: white;
}
.det-box{
  background: var(--bg_light);
  box-shadow: 0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  color: white;
  text-align: center;
  padding: 4vh;
  gap: 20px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
.biz-det,.proj-det{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.biz-det-opts{
  display: flex;
  gap: 15px;
  position: absolute;
  right: 30px;
  top: 30px;
}
.biz-det h1,.proj-det h1{
  font-family: gilroy-bold,sans-serif;
  letter-spacing: 2px;
  margin: 0;
}
.biz-p{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin: 0;
}
.biz-p-2{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  flex-direction: column;
  width: 50%;
  margin: 0;
}
.proj-cards-top{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  color: white;
  margin-top: 3%;
}
.proj-card{
  padding: 3vh 1.5vw;
  padding-left: 2vw;
  background: var(--bg_light);
  box-shadow: 0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 16px;
}
.proj-card-header{
  display: flex;
  justify-content: space-between;
}
.proj-card-opts{
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: center;
  z-index: 0;
}
.graph-opts-wrapper{
  display: flex;
  align-items: center;
  gap: 15px;
}
.proj-card-header h1{
  font-size: 24px;
  font-family: 'gilroy-bold';
  letter-spacing: 1px;
}
.proj-card-cont p{
  font-size: 14px;
  letter-spacing: 1px;
  margin-top: .5em;
  margin-bottom: 0;
}
.proj-card-cont p span{
  font-weight: bold;
}
.proj-add-card{
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.proj-add-card h1{
  letter-spacing: 2px;
  font-size: 26px;
}
.biz-box{
  margin-bottom: 3%;
}
.key-list-head{
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  font-family: 'gilroy-bold';
}
.key-list-item{
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-bottom: 1px solid white !important;
  border-radius: 0 !important;
  padding: 0 !important;
  margin: 0 5px 20px 20px !important;
}
.key-list-icons{
  gap: 11px;
  display: flex;
  flex-direction: row;
}
.key-list div img{
  width: 20px;
  transition: .5s;
}
.key-list div img:hover{
  opacity: .8;
}
@media only screen and (max-width: 1024px){
  .proj-cards-top{
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width:767px){
  .noti-header{
    flex-direction: column;
    gap: 10px;
  }
  .biz-top{
    padding: 16px;
  
  }
  .det-box{
    background-color: var(--bg_light);
    margin-top: 30px;
  }
  .proj-cards-top{
    grid-template-columns: 1fr;
    gap: 10px;
  }
  .proj-card{
    background: var(--bg_light);
    padding-left: 0;
    padding: 7vw;
  }
  .biz-header{
    flex-direction: column !important;
  }
  .biz-det-opts{
    position: unset;
  }
}