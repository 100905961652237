.sidebar-top{
  width: 20%;
  padding: 2%;
  display: flex;
  flex-direction: column;
  background: var(--bg_dark);
  color: white;
  max-height: 100vh;
  min-height: 100vh;
  height: 90%;
  font-size: 18px;
  position: relative;
}
.collapsed-sidebar-top{
  width: auto !important;
  padding: 2% !important;
}
.collapsed-sidebar-top .logo{
  height: 10%;
}
.logo{
  height: auto;
  margin: 5% 0 0% 0;
}
.logo img{
  width: 80%;
  display: block;
  margin: auto;
  margin-top: 25px;
  margin-bottom: 25px;
}
.logo h2{
  margin: 5% 0 15% 0;
  letter-spacing: 5px;
  font-weight: bold;
  text-align: center;
  font-size: 25px;
  font-family: 'gilroy-bold';
}
.menu-top{
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  gap: 35px;
}
.menu-top::-webkit-scrollbar{
  display: none;
}

.menu-box-1{
  display: flex;
  flex-direction: column;
  gap: 35px;
}
.menu-box-2{
  display: flex;
  flex-direction: column;
  gap: 35px;
}
.menu-item-top{
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 15px;
  
}
.menu-item-top a{
  color: var(--font_light);
  text-decoration: none;
  cursor: pointer;
  transition: .5s;
  font-size: 1.4vw;
}
.logout-but{
  color: var(--font_light_red);
  margin: 0;
  cursor: pointer;
  font-size: 1.4vw;
}
.noti-count{
  border-radius: 100%;
  background: #ffffff21;
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.noti-count p{
  font-size: 10px;
  color: white;
}
.collapse-but{
  position: absolute;
  width: 80px;
  height: 80px;
  background-color: var(--bg_light);
  clip-path: circle(50% at 100%);
  right: 0;
  cursor: pointer;
}
.collapse-but::before{
  content: '\2190';
  color: white;
  position: absolute;
  top: 25px;
  right: 5px;
  font-size: 25px;
}
.burger-img{
  cursor: pointer;
}
.collapsed-menu-item-top{
  justify-content: center;
}
.nav-active a{
  color: #3777BE !important;
  font-weight: bold !important;
}
.mobile-nav-cross{
  display: none;
}
.nav-layout-top{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.navbar-top{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 25px;
  background: var(--bg_light);
  box-shadow: 0 0 47px -6px black;
  z-index: 10000;
  height: 10%;
}
.navbar-logo img{
  width: 30%;
}
.nav-bars{
  display: flex;
  flex-direction: row;
}
.navbar-right{
  display: flex;
  justify-content: space-between;
  width: -webkit-fill-available;
  align-items: center;
}
.profile-button img{
  width: 35px;
  border-radius: 100px;
}
.navbar-dropdown a,.navbar-dropdown p{
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: .5s;
  font-weight: 100;
}
.navbar-dropdown p img{
  width: 16px;
}
.navbar-dropdown p:hover{
  letter-spacing: 1px;
}
.menu-label{
  display: block;
}
.collapsed-sidebar-top .menu-label{
  display: none;
}
.collapse-burger-img{
  width: 20px;
}
@media only screen and (max-width:767px){
  .sidebar-top{
    position: fixed;
    width: 100%;
    z-index: 100000;
    transform: translateX(-100%);
    transition: 1s;
  }
  .collapsed-sidebar-top{
    transform: translateX(0) !important;
    width: 99% !important;
    border-right: 1px solid #393939;
  }
  .collapsed-sidebar-top .menu-label{
    display: block;
  }
  .menu-top{
    align-items: center;
    padding-top: 15px;
  }
  .menu-box-1, .menu-box-2{
    width: 60%;
  }
  .collapse-but{
    display: none;
  }
  .logo{
    display: none;
  }
  .mobile-nav-cross{
    display: block;
    width: 5%;
    margin: 15px;
  }
  .logo img{
    width: 25%;
    margin-top: 0;
  }
  .menu-item-top a{
    font-size: 18px;
  }
  .menu-item-top a img{
    width: 18px;
  }
  .logout-but{
    font-size: 18px;
  }
  .menu-item-top img{
    width: 18px;
  }
  .navbar-right{
    justify-content: flex-start;
    flex-direction: row-reverse;
    gap: 15px;
  }
  .navbar-logo img{
    width: 40%;
  }
  .nav-layout-top{
    height: 100vh;
  }
  .nav-bars{
    height: 90%;
    overflow: auto;
  }
}
@media screen and (min-width: 1400px) {
  .menu-item-top a{
    font-size: 18px;
  }
  .logout-but{
    font-size: 18px;
  }
}
