@font-face {
  font-family: gilroy;
  src: url(./Assets//Fonts/Gilroy-Light.otf);
}
@font-face {
  font-family: gilroy-bold;
  src: url(./Assets//Fonts/Gilroy-ExtraBold.otf);
}
*{
  box-sizing: border-box;
  font-family: gilroy,sans-serif;
}
button{
  cursor: pointer;
}
input, textarea {
  font-size: 16px; /* Ensures the input field text is not too small */
}

:root{
  --bg_dark:#171717;
  --bg_light:#242424;
  --font_light:#6F7181;
  --font_red:#D30000;
  --font_light_red:#FB493F;
  --form_gray:#6F7181;
}
body{
  background: var(--bg_light);
  max-height: 100vh;
  overflow: hidden;
}
.App{
  display: flex;
  flex-direction: row;
}
.containor{
  width: 100%;
  overflow: auto;
  max-height: 100vh;
  height: 90%;
}
.ptr{
  cursor: pointer;
}
.icon{
  cursor: pointer;
}
.empty-message{
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 2px;
}
.ham_menu_img{
  display: none;
}
.leaflet-container{
  width: 100% !important;
  height: 100% !important;
}
.dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--bg_light);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
  right: 0;
  border-radius: 10px;
  
}

.dropdown:hover .dropdown-content {
  display: block;
  border: 1px solid grey;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


@media only screen and (max-width:767px){
  body{
    background: var(--bg_light);
  }
  .ham_menu_img{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3% 6%;
    background: var(--bg_light);
  }
  .ham_menu_img img{
    display: block;
  }
  .ham_menu_img .mobile-logo{
    width: 15%;
  }
  .App{
    flex-direction: column;
  }
  .containor{
    height: auto;
  }
}
@media only screen and (min-width:1440px){
  .login-form-body-top{
    width: 55% !important;
  }
}
button,input{
  cursor: pointer;
}