@media only screen and (max-width: 767px) {
  .bal_day.header_but {
      display: none;
  }
}
.dash-cont{
    padding: 50px;
    color: white;
    width: -webkit-fill-available;
  }
  .dash-header1{
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .header-widget{
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
  }
  .header_but{
    background: var(--bg_light);
    padding: .3em .8em;
    box-shadow: 0px 6px 10px 4px rgb(0 0 0 / 15%), 0px 2px 3px rgb(0 0 0 / 30%);
    border-radius: 24px;
  }
  .header_but p{
    margin: 0;
    font-size: 1.3vw;
    white-space: pre;
  
  }
  .dev_conn{
    border: 1px solid rgba(36, 255, 0, 0.75);
  }
  .bal_data,.bal_day{
    border: 1px solid rgba(239, 239, 239, 0.15);
  }
  .offline_count{
    border: 1px solid var(--font_red);
  
  }
  .round_but{
    width: 5.5vh;
    height: 5.5vh;
    box-shadow: 0px 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px rgba(0, 0, 0, 0.3);
    border-radius: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--bg_light);
  }
  
  .settings_but{
    background: white;
  }
  .graph-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .device-name h1{
    font-size: 2em;
    line-height: 59px;
    font-family: gilroy-bold,sans-serif;
    letter-spacing: 3px;
  }
  .graph-header-widget-top{
    display: flex;
    gap: 30px;
  }
  .graph-header-widget{
    display: flex;
    align-items: center;
    gap: 15px;
    background: var(--bg_light);
    box-shadow: 0px 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px rgba(0, 0, 0, 0.3);
    border-radius: 9px;
    padding: .5em 1em;
    position: relative;
    cursor: pointer;
  }
  .graph-header-widget p{
    margin: 0;
  }
  .chart-box{
    background: var(--bg_light);
    box-shadow: 0px 8px 12px 6px rgb(0 0 0 / 15%), 0px 4px 4px rgb(0 0 0 / 30%);
    border-radius: 16px;
 
    display: flex;
    justify-content: center;
    align-items: center;
    color: black !important;
    flex-direction: column;
  }
  .chart-box{
    height: 25em;
    position: relative;
    padding-top: 50px;
    margin: 10px;
    width: calc(50% - 20px);
  }
  .graph-top{
    display: grid;
    grid-template-columns: repeat(1fr, 12);
  }
  .map-frame{
    width: 100%;
    height: 100%;
    border-radius: 16px;
  }
  .graph-title{
    top: 0px;
    left: 30px;
    font-size: 18px;
    font-weight: bold;
    color: white !important;
    margin: 0;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .graph-opts{
    position: absolute;
    z-index: 9999;
    top: 20px;
    width: 90%;
    justify-content: space-between;
  }

  
  .alert-graph-card{
    width: 100%;
    height: 100%;
    border-radius: inherit;
    color: white;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    padding: 30px;
  }
  .alert-graph-content{
    font-size: 40px;
    font-weight: bolder;
    text-align: center;
  }
  .fixed-graph-content{
    color: white;
    margin: 0;
    font-size: 50px;
    letter-spacing: 5px;
  }
  .dash-header-mobile{
    display: none;
  }
  .CircularProgressbar .CircularProgressbar-path {
    stroke: url('#progress-gradient') !important;
  }
  .CircularProgressbar{
    padding: 30px 0px 30px 0px;
  
  }
  .bar-grad-svg{
    position: absolute;
  }
  .xblock{
    padding: 10px;
  }
  .slider-text{
    text-align: start;
    color: white;
    font-family: 'gilroy-bold';
    letter-spacing: 2px;
  }
  .range-slider__thumb[data-lower] {
    width: 0 !important;
  }
  .range-slider__thumb[data-upper] {
    box-shadow: -2px -2px 8px rgb(0, 0, 0),
                -2px -2px 12px rgba(4, 4, 4, 0.5),
                inset 2px 2px 4px rgba(7, 7, 7, 0.1),
                2px 2px 8px rgba(0, 0, 0, .3) !important;
    background-color: #d7b62e !important;
  }
  .level-chart .range-slider__thumb[data-upper] {
    box-shadow: -2px -2px 8px rgb(0, 0, 0),
                -2px -2px 12px rgba(4, 4, 4, 0.5),
                inset 2px 2px 4px rgba(7, 7, 7, 0.1),
                2px 2px 8px rgba(0, 0, 0, .3) !important;
    background-color: #1dce49 !important;
    width: 50px !important;
    height: 30px !important;
    border-radius: 10px !important;
  }
  .horizontal-level-chart .range-slider__thumb[data-upper] {
    height: 50px !important;
    width: 30px !important;
  }

  .slider-chart.range-slider{
    width: 80% !important;
    appearance: none;
    height: 15px !important;
    border: none !important;
    border-radius: 5px !important;
    background-color: #ecf0f3 !important;
    box-shadow: inset -2px -2px 8px rgba(255, 255, 255, 1),
                inset -2px -2px 12px rgba(255, 255, 255, 0.5),
                inset 2px 2px 4px rgba(255, 255, 255, 0.1),
                inset 2px 2px 8px rgba(0, 0, 0, .3) !important;
    outline: none;
    cursor: pointer;
  }
  .slider-chart .range-slider__range {
    border-radius: 5px 0 0 5px !important;
    background-color: #d7b62e !important;
  }
  .slider-box{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .vertical-level-chart.range-slider{
    height: 80% !important;
    width: 40px !important;
  
  }
  .horizontal-level-chart.range-slider{
    height: 40px !important;
    width: 80% !important;
  
  }
  .horizontal-level-chart .range-slider__range {
    border-radius: 5px !important;
    background-color: #2ed75b !important;
  }
  .vertical-level-chart .range-slider__range {
    border-radius: 5px !important;
    background-color: #2ed75b !important;
    top: 0% !important;
  }
  .level-chart-value{
    color: white;
    font-family: 'gilroy-bold';
    letter-spacing: 2px;
    font-size: 20px;
    margin-bottom: 0;
  }
  .gps-chart-box{
    padding: 5%;
    padding-top: 13%;
  }
  .css-b62m3t-container{
    width: 100%;
  }
  .color-box{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80%;
    gap: 15px;
  }
 
  @media only screen and (max-width:767px){
   
   
    .dash-header1{
      flex-direction: column;
      align-items: end;
      gap: 20px;
      background: var(--bg_light);
      padding: 16px;
    }
    .dash-cont{
      padding: 0;
      padding-bottom: 24%;
      background: var(--bg_light);
    }
    .header-widget p{
      font-size: 13px;
    }
    .header-widget{
      margin-top: -10px;
      width: 100%;
      justify-content: center;
      white-space: nowrap;
    }
    .graph-header{
      flex-direction: column;
      margin-bottom: 5%;
      margin-top: -30px;
    }
    .device-name, .graph-top{
      padding: 16px;
    }
    .device-name h1{
      font-size: 2em;
      margin: 0;
    }
    .graph-header-widget-top{
      width: 100%;
      justify-content: space-around;
    }
    .graph-header-widget{
      background-color: var(--bg_light);
      padding: 2vh 4vw;
    }
    .chart-box{
      background: var(--bg_light);
      width: 100%;
      margin: 0;
      margin-bottom: 10px;
    }
    .noti_but{
      display: none;
    }

    .dash-header-mobile{
      display: block;
      background: var(--bg_light);
      padding: 16px;
      padding: 0 5px 3% 5px;
    }
    .dash-header-mobile .header-widget{
      display: flex;
      flex-wrap: wrap;
    }
    .CircularProgressbar{
      padding: 30px 0px 30px 0px;
    }
  }
  @media screen and (min-width: 1400px) {
    .header_but p{
      font-size: 16px;
    }
  }