.noti-top{
  padding: 2vw;
  color: white;
}
.noti-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3%;
  color: white;
}
.noti-header h1{
  font-family: gilroy-bold,sans-serif;
  letter-spacing: 2px;
  margin: 0;
}
.noti-cards-top{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}
.noti-card{
  padding: 3vh 1.5vw;
  padding-left: 2vw;
  background: var(--bg_light);
  box-shadow: 0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 16px;
}
.noti-card-header{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.noti-card-opts{
  display: flex;
  align-items: center;
  gap: 15px;
}
.noti-card-header h1{
  font-size: 24px;
  font-family: 'gilroy-bold';
  letter-spacing: 1px;
}
.opt-box{
  padding: 5px;
  border-radius: 5px;
}
.noti-card-cont p{
  font-size: 14px;
  letter-spacing: 1px;
}
.noti-card-cont p span{
  font-weight: bold;
}
.noti-add-card{
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.noti-add-card h1{
  letter-spacing: 2px;
  font-size: 26px;
}
@media only screen and (max-width:1024px){
  .noti-cards-top{
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width:767px){
  .noti-top{
    padding: 16px;
  }
  .noti-header{
    margin-top: -20px;
    flex-direction: row;
    justify-content: center;
  }
  .noti-cards-top{
    grid-template-columns: 1fr;
  }
  .noti-card{
    background: var(--bg_light);
    padding: 5%;
  }
  .noti-add-but{
    background: var(--bg_light);
  }
}