/* SendMessageComponent.css */
.send-message-component {
  width: 300px;
  text-align: center;
  margin-left: 70px;
 
 
}
.ARM_RTL-chart-box{
  display: flex;
  justify-content: center;
}

.slider-chart1 {
  /* margin-bottom: 10px; */
  font-family: Verdana, Geneva, Tahoma, sans-serif;

  height: 40px;
  width: 70%;
  background: rgba( 255, 255, 255, 0.2 );
backdrop-filter: blur( 20px );
-webkit-backdrop-filter: blur( 20px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
  border-radius: 100px;
 
}
.slider-chart1 .range-slider__thumb[data-upper] {
  background-color: white !important;
  height: 40px;
  width: 40px;
}
.slider-chart1 .range-slider__range{
  /* background-color: red !important; */
  background: rgba( 136, 7, 7, 0.75 );
backdrop-filter: blur( 14px );
-webkit-backdrop-filter: blur( 14px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
  border-radius: 100px;
}
.slider-labels {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}
.slider-label1{
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 15%;
  left: 2%;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 14px;
  right: 0;
  pointer-events: none;
}
.slider-label3{
  
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 15%;
  left: 58%;
  font-size: 14px;
  right: 0;
  pointer-events: none;
}
.slider-label2{
  display: flex;
  justify-content: space-between;
  position: absolute;
  font-family: revert;
  top: 14%;
  left: 17%;
  right: 0;
  pointer-events: none;
  color: black !important;
}
.slider-labels .label-start,
.slider-labels .label-end {
  font-size: 14px;
  font-weight: bold;
  color: black;
  margin-top: 22px; /* Adjust based on handle size */
}
.slider-container {
  position: relative;
  height: 70px; /* Increase this value to make the slider taller */
  width: 100%; /* Adjust the width as needed */
}

.slider-chart {
  height: 100%; /* Make the slider take the full height of its container */
}

.slider-label {
  position: absolute;
  top: 20px;
  left: 35%;
  font-family: revert;
  transform: translate(-50%, -50%);
  font-size: 1em; /* Adjust the font size as needed */
  color: black; /* Adjust the color as needed */
  pointer-events: none; /* Ensure the label doesn't interfere with slider interaction */
}
