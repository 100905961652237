@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');
.flight-card{
    display: flex;
    flex-direction: column;
    color: red;
    
}
#myfont{
    font-family: "Roboto Mono", monospace !important;
}

body .flight-items{
    width: 100%;
    background: linear-gradient(90deg, rgba(219,81,69,1) 0%, rgba(16,160,219,1) 43%, rgba(235,30,30,1) 91%);;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
    font-size: 20px;
    font-family: "Roboto Mono", monospace !important;
  font-optical-sizing: auto;
  font-weight: bolder;
  font-style:normal;
    display: flex;
    justify-content: space-around;
    margin: 0;
    
}
#fg{
    width: 100%;
}
.heading{
    align-self: center;
}
.message-card{
    display: flex;
    font-size: 18px !important;
    font-family: "Oswald", sans-serif !important;
    flex-direction: column;
    width: 100%;
    margin-left: 40px;
    
    
}
.message-content {
    word-wrap: break-word !important; /* Break long words */
    white-space: pre-wrap; /* Handle white space and line breaks */
    overflow-wrap: break-word; /* Break long words */
    padding: 5px;
    margin: 0;
    
  }
  .message-content.long-text {
    margin: 0;
}