.message-card {
   
    border-radius: 8px;
    padding: 16px;
    max-height: 400px; /* Adjust based on your requirement */
    overflow-y: auto; /* Enables vertical scrolling */
    display: flex;
    flex-direction: column-reverse; /* Display from bottom */
  }
  
  .message-card-content {
    display: flex;
    flex-direction: column;
  }
  
  .message-content {
    margin: 8px 0;
    font-size: 14px;
  }
  