
/* Table Wrapper Styles */
.table-container {
    width: 100%;
    overflow-x: auto; /* Enable horizontal scrolling on small screens */
  }
  
  .table-wrapper {
    max-width: 100%;
    overflow-x: auto; /* Enable horizontal scrolling on small screens */
  }
  
  /* Responsive Table Styles */
  .responsive-table {
    width: 90%;
    border-collapse: collapse;
    color: white !important;
    margin-left: 20px;
  }
  
  .responsive-table th, .responsive-table td {
    padding: 8px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  /* Responsive Design: Adjust table layout on smaller screens */
  