.signup-top form{
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  max-height: 100%;
  height: auto;
  gap: 10px;
}
.signup-form{
  background: var(--bg_light);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  padding: 15px 0 15px 0;
  max-height: 100%;
  height: auto;
}
.signup-top form label{
  display: flex;
  flex-direction: column;
}
.signup-top{
  width: 100%;
  padding: 5%;
  display: flex;
  justify-content: center;
  height: 100vh;
  max-height: 100vh;
  background-image: url('../../Assets/Images/banner.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.login-box{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 100vh;
  height: 100vh;
  background-image: url('../../Assets/Images/banner.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.login-box form{
  width: 40%;
  background: var(--bg_light);
  backdrop-filter: blur(2px);
}
.signup-form .form-box{
  background: none;
  box-shadow: none;
}
.login-head p{
  font-size: 36px;
  font-family: 'gilroy-bold';
  margin-top: 0;
}
.login-form-body-top{
  width: 80%;
  gap: 10px;
  display: flex;
  flex-direction: column;
}
.login-form-body{
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.login-form-body-top a{
  text-decoration: none;
  color: #dadada;
  font-size: 12px;
  letter-spacing: 1px;
}
.login-label{
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-radius: 12px;
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 700;
}
.login-label input{
  padding: 10px;
  border-radius: 12px;
  
}
.login-label:focus-visible{
  outline: none;
}
.login-but-group{
  padding-top: 12%;
}
.login-but{
  background: rgb(36,0,10);
background: linear-gradient(90deg, rgba(36,0,10,1) 0%, rgba(121,9,32,1) 44%, rgba(68,0,255,1) 100%);
  width: 100%;
  color: white;
  border-radius: 12px;
  padding: 10px;
  font-family: 'gilroy-bold';
  letter-spacing: 2px;
  border: none;
  cursor: pointer;
  transition: .5s;
}
.login-but:active{
  transform: scale(1.1);
}
.login-but:focus-visible{
  outline: none;
}
.login-reg-link{
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  color: white;
}
.login-reg-link a{
  font-size: 14px;
  font-family: 'gilroy-bold';
  color: white;
  text-decoration: none;
}
.login-logo img{
  width: 130px;
  margin: auto;
  display: block;
}
.non-modal{
  width: 100%;
}
@media only screen and (max-width:767px){
  .login-box form, .signup-form{
    width: 95%;
  }
  .login-form-body-top{
    width: 80%;
  }
  .login-logo img{
    width: 40%;
  }
}