.notifications .container {
  background-color: var(--bg_light) !important;
  border: 1px solid rgba(255, 255, 255, 0.18);
  z-index: 10000000 !important;
  color: white !important;
  padding: 20px; 
  display: flex;
  flex-direction: column;
  max-width: 100% !important;
  flex-wrap: wrap;
  border-radius: 20px;
  transform: translateX(-350px) !important;
  margin-top: 20px;
}



@media (max-width: 480px) {
  .notifications .container {
    display: flex;
    flex-direction: column;
    max-width: 380px !important; 
    width: auto !important; 
    min-width: 300px !important;
    padding: 10px;
    transform: translateX(-20px) !important;
    margin-top: 10px;
    box-sizing: border-box; 
}

  .notifications{
    width: 600px !important;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .notifications .container {
    display: flex;
    flex-direction: column;
    
    width: auto; 
    min-width: 400px !important;
    padding: 10px;
    transform: translateX(-280px) !important;
    margin-top: 10px;
    box-sizing: border-box;
   
    
  }
}


.notifications .items .card {
  background: #0000;
}

.notifications .items .card:hover {
  background: var(--bg_light);
}

.notifications .header-title {
  color: white !important;
  font-size: 14px;
}

.notifications .text {
  color: white !important;
  font-size: 15px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); 
}

.notifications .card .image img {
  height: unset;
  border-radius: 0;
}

.notifications .icon, .notifications .icon .image {
  height: 20px !important;
  width: 20px !important;
}

.card {
  padding: 10px !important;
}

.notifications .container .header .header-option {
  color: blue !important;
  font-size: 14px;
}



#notiform, input[type="submit"] {
  width: 100px;
  border-radius: 50px;
  height: 50px; /* Set desired height */
  box-sizing: border-box; /* Ensure padding and border are included */
  padding: 10px; /* Adjust padding if necessary */
}
