.pricing-table{
  width: 100%;
  padding: 50px;
  padding-bottom: 150px;
  display: grid;
  align-items: center;
  justify-content: space-around;
  grid-template-columns: 312px 312px 312px;
  gap: 50px;
  justify-content: center;
}
.pricing-table-top{
  background: #1E1E2D;
  width: 100%;
  height: 100vh;
  overflow: auto;
}
.pricing-table-head{
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pricing-table-head h1{
  color: white;
  font-family: 'gilroy-bold';
  display: flex;
  align-items: center;
}
.pricing-table-head h1 img{
  width: 50px;
  color: white;
  font-size: 30px;
  padding-right: 15px;
  cursor: pointer;
}
.pay-table-head-right>img{
  width: 100px;
}
.pay-table-head-right{
  display: flex;
  align-items: center;
  gap: 35px;
}
.pay-table-head-right p{
  color: #e0443c;
  margin: 0;
  font-family: 'gilroy';
  letter-spacing: 2px;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}
.pay-card{
  background: var(--bg_light);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6.4px);
  -webkit-backdrop-filter: blur(6.4px);
  border: 1px solid #1E1E2D;
  color: white;
  padding: 30px;
  width: 312px;
}
.pay-card-head h2{
  font-family: 'gilroy-bold';
  letter-spacing: 2px;
  margin-bottom: 5px;
}
.pay-card-head p{
  margin: 0;
  font-size: 14px;
}
.pay-card-cont{
  display: flex;
  align-items: center;
  gap: 5px;
}
.pay-card-cont-amnt{
  font-family: 'gilroy-bold';
  font-size: 40px;
}
.pay-card-cont-intvl{
  display: flex;
  flex-direction: column;
}
.pay-card-but{
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  background: white;
  border: none;
  color: white;
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 2px;
  color: #1E1E2D;
  font-family: 'gilroy-bold';
  cursor: pointer;
}
.checkout-btn{
  width: 80%;
  padding: 15px;
  border-radius: 5px;
  background: white;
  border: none;
  color: white;
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 2px;
  color: #1E1E2D;
  font-family: 'gilroy-bold';
  cursor: pointer;
}
.StripeElement{
  width: 100% !important;
}
@media only screen and (max-width: 1200px){
  .pricing-table{
    grid-template-columns: 312px 312px;
  }
  
}

@media only screen and (max-width: 767px){
  .pay-table-head-right{
    display: none;
  }
  .pricing-table{
    grid-template-columns: 312px;
    gap: 25px;
  }
  .checkout-btn{
    width: 100%;
  }
  .pricing-table-head h1{
    font-size: 20px;
  }
  .pricing-table-head{
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .pricing-table{
    padding: 0;
  }
}

