


.form-label, .form-react-select{
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 2px;
  width: 60%;
  align-items: center;
}
.form-label-checkbox{
  font-size: 14px;
 
  display: flex;
  width: 50%;
  justify-content: space-between;
}
.form-label-checkbox .checkbox-p{
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.form-but-1{
  background: #FFFFFF;
  border-radius: 9.54146px;
  font-family: gilroy-bold,sans-serif;
  letter-spacing: 1px;
  font-size: 14px;
  line-height: 13px;
  
  padding: 1vh 1vw;
  border: 1px solid white;
  height: 2.3em;
  min-width: 7.5vw;
  width: auto;
}
.form-but-2{
  border: 0.894512px solid #FFFFFF;
  border-radius: 9.54146px;
  background: transparent;
  padding: 1vh 1vw;
  color: white;
  letter-spacing: 1px;
  font-size: 14px;
  border: 1px solid white;
  height: 2.3em;
  min-width: 7.5vw;
  width: auto;
}
.form-but-1 a, .form-but-2 a{
  color: white;
  text-decoration: none;
}
.form-but-group{
  gap: 15px;
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.form-box{
  box-shadow: 0px 8px 12px 6px rgb(0 0 0 / 15%), 0px 4px 4px rgb(0 0 0 / 30%);
  border-radius: 16px;
  background: #303043;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  color: white;
  width: 100%;

}
.form-label input:not([type='checkbox']),.form-label textarea,.form-label select{
  background-color: rgba( 47, 47, 47, 1 );;
  box-shadow: 0px 7.2px 10.8px 5.4px rgba(0, 0, 0, 0.15), 0px 3.6px 3.6px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  border: none;
  color: white; 

border-radius: 10px;
  height: 50px;
  padding: 10px;
  width: 100%;
}



/* Ensuring the placeholder color remains white */
.form-label .input-min-width-95p::placeholder {
  color: white;
  opacity: 1;
}


/* Adjusting the dropdown picker within react-datetime */
.form-label .rdtPicker {
  background: rgba(76, 76, 76, 0.65); /* Fixed comma to semicolon */
  backdrop-filter: blur(9.5px); /* Fixed comma to semicolon and corrected property name */
  border-radius: 10px; /* Fixed comma to semicolon */
  border: 1px solid rgba(255, 255, 255, 0.18); /* Fixed comma to semicolon */
}

.dateinp{
  width: 100% !important;
}
.rdt{
  width: 100% !important;
}


.form-box-top{
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;
 width: 55%;
 flex-direction: column;
}
.modal{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: brightness(0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3% 0;
  color: white;
  z-index: 10001;
}
.modal form{
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3% 0;
  overflow: auto;
  max-height: 100%;
  gap: 25px;
  position: relative;
  background: rgba( 47, 47, 47, 1 );
  backdrop-filter: blur( 12px );
  -webkit-backdrop-filter: blur( 12px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}
.modal form::-webkit-scrollbar{
  width: 2px;
}
.form-cross-but{
  width: 3%;
  position: absolute;
  right: 7px;
  top: 7px;
  cursor: pointer;
  z-index: 10;
}
.device-ver-top, .device-ver-top form{
  width: 100%;
}
.form-label-tooltip{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.form-label-tooltip>a{
  height: 20px;
  cursor: pointer;
}
.form-label-tooltip>a>img{
  width: 20px;
}
.form-label>img{
  width: 50%;
  border-radius: 15px;
}
.form-err-span{
  font-size: 12px;
  color: #FB493F;
  width: 100%;
  text-align: left;
  font-weight: bold;
}
/* write media query for mobile screens */
@media only screen and (max-width:400px){
  #graph_setting>form{
    width: 100% !important;
  }
}
@media only screen and (max-width:767px){

  .modal>form{
    width: 80%;
    padding: 5%;
  }
  .form-but-1, .form-but-2{
    padding: 1vh 2vw;
    min-width: 10vw;
  }
  .form-label, .form-react-select{
    width: 100%;
  }
}
.css-1fdsijx-ValueContainer .ss-qbdosj-Input {
  grid-area: initial !important; 
}
.css-1u9des2-indicatorSeparator{
  display: none;
}
.css-19bb58m >input {
  position: absolute;
  left: -9999px;
  width: 1px;
  height: 1px;
  overflow: hidden;
  
} 



select{
  appearance: none;
 
  -webkit-appearance: none;
}


select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: rgba(47, 47, 47, 1);
  color: white;
  border: none;
  padding: 10px;
  height: 50px;
  border-radius: 8px;
  box-shadow: 0px 7.2px 10.8px 5.4px rgba(0, 0, 0, 0.15), 0px 3.6px 3.6px rgba(0, 0, 0, 0.3);
  width: 100%;
  cursor: pointer;
}


select::-ms-expand {
  display: none;
}

.select-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}



select:focus {
  outline: none;
}
.form-input::placeholder {
  color: white;
  opacity: 1; 
}

.select-wrapper select:checked{
  background-color: #242424;
}
option {
  background-color: #242424;
}
option:checked {  
  background-color: #242424;
  color:  white !important;
}
option:focus{
  background-color: #242424;
}

.form-but-11:active{
  transform: scale(1.1);
}
.form-but-11:focus-visible{
  outline: none;
}


