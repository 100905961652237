.sub-det-main{
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  padding: 30px;
  height: 100vh;
}
.sub-det-card-top{
  display: flex;
  flex-direction: row;
  gap: 50px;
  flex-wrap: wrap;
  align-items: flex-start;
}
.sub-det-card{
  width: fit-content;
  padding: 25px;
  background: var(--bg_light);
  border-radius: 15px;
  color: white;
  box-shadow: 0 0 21px -5px black;
}
.sub-det-card-head{
  display: flex;
  align-items: center;
  gap: 15px;
}
.card-status{
  font-size: 10px;
  padding: 3px 10px;
  border-radius: 5px;
  letter-spacing: 2px;
  font-weight: bold;
  margin: 0;
}
.card-status.active{
  background: #1a9841;
}
.card-status.inactive{
  background: #b33427;
}
.sub-det-card-cont p{
  font-size: 14px;
}
.sub-det-card-cont p span{
  font-weight: bold;
  letter-spacing: 2px;
}
.card-title{
  margin: 0;
}
.cancel-btn{
  background: #73150e;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  margin-right: 10px;
}
.recharge-btn{
  background: #0e7331;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  cursor: pointer;
}
.sub-det-device-table{
  margin: unset;
  overflow: auto;
}
.sub-det-table-row{
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.inv-status{
  width: fit-content;
  background: #ff9999;
  padding: 3px 10px;
  border-radius: 5px;
  margin: 0;
  text-transform: capitalize;
  color: #ad0f04;
  font-weight: bolder;
  letter-spacing: 1px;
  font-size: 10px;
}
.inv-status.paid{
  color: rgb(0,105,8) !important;
  background: #d7f7c2 !important;
}
@media only screen and (max-width: 767px){
  .main{
    width: 100%;
  }
  .sub-det-card{
    width: 100%;
    background-color: var(--bg_light);
  }
  .sub-det-card-top{
    gap: 20px;
  }
  .table-row:first-of-type{
    background: var(--bg_light);
  }
  .sub-det-main{
    justify-content: start;
  }
}